/**
 * Contains all portrait images
 *
 * @file   PortraitImages.jsx.
 * @author Jacob Summerville.
 * @since  02/19/2024
 */

import React from "react";

import "./../../styles/theme.scss";
import "./../../styles/portfolio.scss";

import ImageGallery from "./ImageGallery";

import damaris202202_01 from "./../../assets/portrait/2022-02-damaris/damaris1.webp";
import damaris202202_02 from "./../../assets/portrait/2022-02-damaris/damaris2.webp";
import damaris202202_03 from "./../../assets/portrait/2022-02-damaris/damaris3.webp";
import damaris202202_04 from "./../../assets/portrait/2022-02-damaris/damaris4.webp";
import damaris202202_05 from "./../../assets/portrait/2022-02-damaris/damaris5.webp";
import damaris202202_06 from "./../../assets/portrait/2022-02-damaris/damaris6.webp";
import damaris202202_07 from "./../../assets/portrait/2022-02-damaris/damaris7.webp";
import damaris202202_08 from "./../../assets/portrait/2022-02-damaris/damaris8.webp";
import damaris202202_09 from "./../../assets/portrait/2022-02-damaris/damaris9.webp";

import jenny202203_01 from "./../../assets/portrait/2022-03-jenny/jenny1.webp";
import jenny202203_02 from "./../../assets/portrait/2022-03-jenny/jenny2.webp";
import jenny202203_03 from "./../../assets/portrait/2022-03-jenny/jenny3.webp";
import jenny202203_04 from "./../../assets/portrait/2022-03-jenny/jenny4.webp";
import jenny202203_05 from "./../../assets/portrait/2022-03-jenny/jenny5.webp";
import jenny202203_06 from "./../../assets/portrait/2022-03-jenny/jenny6.webp";
import jenny202203_07 from "./../../assets/portrait/2022-03-jenny/jenny7.webp";

import lauren202210_01 from "./../../assets/portrait/2022-10-lauren/lauren1.webp";
import lauren202210_02 from "./../../assets/portrait/2022-10-lauren/lauren2.webp";
import lauren202210_03 from "./../../assets/portrait/2022-10-lauren/lauren3.webp";
import lauren202210_04 from "./../../assets/portrait/2022-10-lauren/lauren4.webp";
import lauren202210_05 from "./../../assets/portrait/2022-10-lauren/lauren5.webp";

import rhyann202211_01 from "./../../assets/portrait/2022-11-rhyann/rhyann1.webp";
import rhyann202211_02 from "./../../assets/portrait/2022-11-rhyann/rhyann2.webp";
import rhyann202211_03 from "./../../assets/portrait/2022-11-rhyann/rhyann3.webp";
import rhyann202211_04 from "./../../assets/portrait/2022-11-rhyann/rhyann4.webp";

import ashley202306_01 from "./../../assets/portrait/2023-06-ashley/ashley1.webp";
import ashley202306_02 from "./../../assets/portrait/2023-06-ashley/ashley2.webp";
import ashley202306_03 from "./../../assets/portrait/2023-06-ashley/ashley3.webp";
import ashley202306_04 from "./../../assets/portrait/2023-06-ashley/ashley4.webp";
import ashley202306_05 from "./../../assets/portrait/2023-06-ashley/ashley5.webp";
import ashley202306_06 from "./../../assets/portrait/2023-06-ashley/ashley6.webp";
import ashley202306_07 from "./../../assets/portrait/2023-06-ashley/ashley7.webp";
import ashley202306_08 from "./../../assets/portrait/2023-06-ashley/ashley8.webp";
import ashley202306_09 from "./../../assets/portrait/2023-06-ashley/ashley9.webp";
import ashley202306_10 from "./../../assets/portrait/2023-06-ashley/ashley10.webp";

import zoey202307_01 from "./../../assets/portrait/2023-07-zoey/zoey1.webp";
import zoey202307_02 from "./../../assets/portrait/2023-07-zoey/zoey2.webp";
import zoey202307_03 from "./../../assets/portrait/2023-07-zoey/zoey3.webp";
import zoey202307_04 from "./../../assets/portrait/2023-07-zoey/zoey4.webp";
import zoey202307_05 from "./../../assets/portrait/2023-07-zoey/zoey5.webp";
import zoey202307_06 from "./../../assets/portrait/2023-07-zoey/zoey6.webp";
import zoey202307_07 from "./../../assets/portrait/2023-07-zoey/zoey7.webp";

import jakegabby202312_01 from "./../../assets/portrait/2023-12-jake-gabby/jake-gabby1.webp";
import jakegabby202312_02 from "./../../assets/portrait/2023-12-jake-gabby/jake-gabby2.webp";
import jakegabby202312_03 from "./../../assets/portrait/2023-12-jake-gabby/jake-gabby3.webp";
import jakegabby202312_04 from "./../../assets/portrait/2023-12-jake-gabby/jake-gabby4.webp";
import jakegabby202312_05 from "./../../assets/portrait/2023-12-jake-gabby/jake-gabby5.webp";

import enzio202402_01 from "./../../assets/portrait/2024-02-enzio/enzio1.webp";
import enzio202402_02 from "./../../assets/portrait/2024-02-enzio/enzio2.webp";
import enzio202402_03 from "./../../assets/portrait/2024-02-enzio/enzio3.webp";
import enzio202402_04 from "./../../assets/portrait/2024-02-enzio/enzio4.webp";

import lily202403_01 from "./../../assets/portrait/2024-03-lily/lily1.webp"
import lily202403_02 from "./../../assets/portrait/2024-03-lily/lily2.webp"
import lily202403_03 from "./../../assets/portrait/2024-03-lily/lily3.webp"
import lily202403_04 from "./../../assets/portrait/2024-03-lily/lily4.webp"
import lily202403_05 from "./../../assets/portrait/2024-03-lily/lily5.webp"
import lily202403_06 from "./../../assets/portrait/2024-03-lily/lily6.webp"
import lily202403_07 from "./../../assets/portrait/2024-03-lily/lily7.webp"
import lily202403_08 from "./../../assets/portrait/2024-03-lily/lily8.webp"
import lily202403_09 from "./../../assets/portrait/2024-03-lily/lily9.webp"
import lily202403_10 from "./../../assets/portrait/2024-03-lily/lily10.webp"
import lily202403_11 from "./../../assets/portrait/2024-03-lily/lily11.webp"
import lily202403_12 from "./../../assets/portrait/2024-03-lily/lily12.webp"
import lily202403_13 from "./../../assets/portrait/2024-03-lily/lily13.webp"

import jeny202403_01 from "./../../assets/portrait/2024-03-jeny/jeny1.webp"
import jeny202403_02 from "./../../assets/portrait/2024-03-jeny/jeny2.webp"
import jeny202403_03 from "./../../assets/portrait/2024-03-jeny/jeny3.webp"
import jeny202403_04 from "./../../assets/portrait/2024-03-jeny/jeny4.webp"
import jeny202403_05 from "./../../assets/portrait/2024-03-jeny/jeny5.webp"
import jeny202403_06 from "./../../assets/portrait/2024-03-jeny/jeny6.webp"
import jeny202403_07 from "./../../assets/portrait/2024-03-jeny/jeny7.webp"
import jeny202403_08 from "./../../assets/portrait/2024-03-jeny/jeny8.webp"
import jeny202403_09 from "./../../assets/portrait/2024-03-jeny/jeny9.webp"
import jeny202403_10 from "./../../assets/portrait/2024-03-jeny/jeny10.webp"
import jeny202403_11 from "./../../assets/portrait/2024-03-jeny/jeny11.webp"

import anzhela202404_01 from "./../../assets/portrait/2024-04-anzhela/anzhela1.webp";
import anzhela202404_02 from "./../../assets/portrait/2024-04-anzhela/anzhela2.webp";
import anzhela202404_03 from "./../../assets/portrait/2024-04-anzhela/anzhela3.webp";
import anzhela202404_04 from "./../../assets/portrait/2024-04-anzhela/anzhela4.webp";
import anzhela202404_05 from "./../../assets/portrait/2024-04-anzhela/anzhela5.webp";
import anzhela202404_06 from "./../../assets/portrait/2024-04-anzhela/anzhela6.webp";
import anzhela202404_07 from "./../../assets/portrait/2024-04-anzhela/anzhela7.webp";
import anzhela202404_08 from "./../../assets/portrait/2024-04-anzhela/anzhela8.webp";
import anzhela202404_09 from "./../../assets/portrait/2024-04-anzhela/anzhela9.webp";
import anzhela202404_10 from "./../../assets/portrait/2024-04-anzhela/anzhela10.webp";
import anzhela202404_11 from "./../../assets/portrait/2024-04-anzhela/anzhela11.webp";

import misc_01 from "./../../assets/portrait/misc/misc1.webp";
import misc_02 from "./../../assets/portrait/misc/misc2.webp";
import misc_03 from "./../../assets/portrait/misc/misc3.webp";
import misc_04 from "./../../assets/portrait/misc/misc4.webp";
import misc_05 from "./../../assets/portrait/misc/misc5.webp";
import misc_06 from "./../../assets/portrait/misc/misc6.webp";
import misc_07 from "./../../assets/portrait/misc/misc7.webp";
import misc_08 from "./../../assets/portrait/misc/misc8.webp";
import misc_09 from "./../../assets/portrait/misc/misc9.webp";
import misc_10 from "./../../assets/portrait/misc/misc10.webp";
import misc_11 from "./../../assets/portrait/misc/misc11.webp";
import misc_12 from "./../../assets/portrait/misc/misc12.webp";

/*-----------------------------------------------------------------------

/* Damaris 02/2022 */
const Damaris202202 = () => {
  const photos = [
    { src: damaris202202_01, href: damaris202202_01, width: 667, height: 1000, originalAlt: 'Nature portrait photo in Agua Caliente Park (Tucson, Arizona) of Damaris standing against a wall in a white dress with short hair with blue highlights.' },
    { src: damaris202202_02, href: damaris202202_02, width: 800, height: 1000, originalAlt: 'Nature portrait photo in Agua Caliente Park (Tucson, Arizona) of Damaris behind leaves in a white dress with short hair with blue highlights.' },
    { src: damaris202202_03, href: damaris202202_03, width: 800, height: 1000, originalAlt: 'Nature portrait photo in Agua Caliente Park (Tucson, Arizona) of Damaris behind a plant in a white dress with short hair with blue highlights.' },
    { src: damaris202202_04, href: damaris202202_04, width: 800, height: 1000, originalAlt: 'Nature portrait photo in Agua Caliente Park (Tucson, Arizona) of Damaris in front of the lake in a white dress with short hair with blue highlights.' },
    { src: damaris202202_05, href: damaris202202_05, width: 800, height: 1000, originalAlt: 'Nature portrait photo in Agua Caliente Park (Tucson, Arizona) of Damaris in front of the lake in a white dress with short hair with blue highlights.' },
    { src: damaris202202_06, href: damaris202202_06, width: 800, height: 1000, originalAlt: 'Nature portrait photo in Agua Caliente Park (Tucson, Arizona) of Damaris in front of a field in a white dress with short hair with blue highlights.' },
    { src: damaris202202_07, href: damaris202202_07, width: 668, height: 1000, originalAlt: 'Nature portrait photo in Agua Caliente Park (Tucson, Arizona) of Damaris with short hair and blue highlights.' },
    { src: damaris202202_08, href: damaris202202_08, width: 668, height: 1000, originalAlt: 'Nature portrait photo in Agua Caliente Park (Tucson, Arizona) of Damaris with short hair and blue highlights.' },
    { src: damaris202202_09, href: damaris202202_09, width: 668, height: 1000, originalAlt: 'Nature portrait photo in Agua Caliente Park (Tucson, Arizona) of Damaris with short hair and blue highlights.' }
  ];

  return (
    <>
      <ImageGallery
        title="Damaris 02/2022"
        name="Damaris"
        date="February, 2022"
        location="Tucson, Arizona"
        photoArray={photos}
        returnLink="/portfolio/portraits"
      />
    </>
  );
};


/*-----------------------------------------------------------------------

/* Jenny 03/2022 */
const Jenny202203 = () => {
  const photos = [
    { src: jenny202203_01, href: jenny202203_01, width: 667, height: 1000, originalAlt: 'Nature portrait photo in Agua Caliente Park (Tucson, Arizona) of Jenny standing next to a lake with fairy wings and a white dress with brunnette hair.' },
    { src: jenny202203_02, href: jenny202203_02, width: 800, height: 1000, originalAlt: 'Nature portrait photo in Agua Caliente Park (Tucson, Arizona) of Jenny standing next to a lake with fairy wings and a white dress with brunnette hair.' },
    { src: jenny202203_03, href: jenny202203_03, width: 666, height: 1000, originalAlt: 'Nature portrait photo in Agua Caliente Park (Tucson, Arizona) of Jenny standing next to a lake with fairy wings and a white dress with brunnette hair.' },
    { src: jenny202203_04, href: jenny202203_04, width: 800, height: 1000, originalAlt: 'Nature portrait photo in Agua Caliente Park (Tucson, Arizona) of Jenny standing on a bridge next to a lake in a red dress with brunnette hair.' },
    { src: jenny202203_05, href: jenny202203_05, width: 800, height: 1000, originalAlt: 'Nature portrait photo in Agua Caliente Park (Tucson, Arizona) of Jenny standing against a wall and in a red dress with brunnette hair.' },
    { src: jenny202203_06, href: jenny202203_06, width: 665, height: 1000, originalAlt: 'Nature portrait photo in Agua Caliente Park (Tucson, Arizona) of Jenny standing next to a palm tree in a red dress with brunnette hair.' },
    { src: jenny202203_07, href: jenny202203_07, width: 800, height: 1000, originalAlt: 'Nature portrait photo in Agua Caliente Park (Tucson, Arizona) of Jenny standing next to a lake in a red dress with brunnette hair.' }
  ];

  return (
    <>
      <ImageGallery
        title="Jenny 03/2022"
        name="Jenny"
        date="March, 2022"
        location="Agua Caliente Park in Tucson, Arizona"
        photoArray={photos}
        returnLink="/portfolio/portraits"
      />
    </>
  );
};

/*-----------------------------------------------------------------------

/* Lauren 10/2022 */
const Lauren202210 = () => {
  const photos = [
    { src: lauren202210_01, href: lauren202210_01, width: 661, height: 1000, originalAlt: 'Nature portrait photo in North Bend, Washington of Lauren standing in front of pine trees and fog doing yoga in black pants and a red shirt and brunnette hair.' },
    { src: lauren202210_02, href: lauren202210_02, width: 800, height: 1000, originalAlt: 'Nature portrait photo in North Bend, Washington of Lauren with a fall colored leaf in front of her face with a red shirt and brunnette hair.' },
    { src: lauren202210_03, href: lauren202210_03, width: 679, height: 1000, originalAlt: 'Nature portrait photo in North Bend, Washington of Lauren with behind leaves in a tree with a red shirt and brunnette hair.' },
    { src: lauren202210_04, href: lauren202210_04, width: 1000, height: 666, originalAlt: 'Nature portrait photo in North Bend, Washington of Lauren lying on a pile of leaves with a red shirt and brunnette hair.' },
    { src: lauren202210_05, href: lauren202210_05, width: 644, height: 966, originalAlt: 'Nature portrait photo in North Bend, Washington of Lauren standing in front of pine trees and a river in black pants and a red shirt and brunnette hair.' }
  ];

  return (
    <>
      <ImageGallery
        title="Lauren 10/2022"
        name="Lauren"
        date="October, 2022"
        location="North Bend, Washington"
        photoArray={photos}
        returnLink="/portfolio/portraits"
      />
    </>
  );
};


/*-----------------------------------------------------------------------

/* Rhyann 11/2022 */
const Rhyann202211 = () => {
  const photos = [
    { src: rhyann202211_01, href: rhyann202211_01, width: 661, height: 1000, originalAlt: 'Nature portrait photo in Wildwood Park in Puyallup, Washington of Rhyann standing in the dark with string lights in a black dress.' },
    { src: rhyann202211_02, href: rhyann202211_02, width: 667, height: 1000, originalAlt: 'Nature portrait photo in Wildwood Park in Puyallup, Washington of Rhyann standing behind tree leaves in a black dress.' },
    { src: rhyann202211_03, href: rhyann202211_03, width: 739, height: 1000, originalAlt: 'Nature portrait photo in Wildwood Park in Puyallup, Washington of Rhyann standing behind tree leaves in a black dress.' },
    { src: rhyann202211_04, href: rhyann202211_04, width: 710, height: 1000, originalAlt: 'Nature portrait photo in Wildwood Park in Puyallup, Washington of Rhyann standing behind tree leaves in a black dress.' }
  ];

  return (
    <>
      <ImageGallery
        title="Rhyann 11/2022"
        name="Rhyann"
        date="November, 2022"
        location="Wildwood Park in Puyallup, Washington"
        photoArray={photos}
        returnLink="/portfolio/portraits"
      />
    </>
  );
};

/*-----------------------------------------------------------------------

/* Ashley 06/2023 */
const Ashley202306 = () => {
  const photos = [
    { src: ashley202306_01, href: ashley202306_01, width: 661, height: 1000, originalAlt: 'Sports portrait photo in Riverview Park in Renton, Washington of Ashley.' },
    { src: ashley202306_02, href: ashley202306_02, width: 662, height: 1000, originalAlt: 'Sports portrait photo in Riverview Park in Renton, Washington of Ashley.' },
    { src: ashley202306_03, href: ashley202306_03, width: 667, height: 1000, originalAlt: 'Sports portrait photo in Riverview Park in Renton, Washington of Ashley.' },
    { src: ashley202306_04, href: ashley202306_04, width: 667, height: 1000, originalAlt: 'Sports portrait photo in Riverview Park in Renton, Washington of Ashley.' },
    { src: ashley202306_05, href: ashley202306_05, width: 667, height: 1000, originalAlt: 'Sports portrait photo in Riverview Park in Renton, Washington of Ashley.' },
    { src: ashley202306_06, href: ashley202306_06, width: 667, height: 1000, originalAlt: 'Sports portrait photo in Riverview Park in Renton, Washington of Ashley.' },
    { src: ashley202306_07, href: ashley202306_07, width: 667, height: 1000, originalAlt: 'Sports portrait photo in Riverview Park in Renton, Washington of Ashley.' },
    { src: ashley202306_08, href: ashley202306_08, width: 667, height: 1000, originalAlt: 'Sports portrait photo in Riverview Park in Renton, Washington of Ashley.' },
    { src: ashley202306_09, href: ashley202306_09, width: 667, height: 1000, originalAlt: 'Sports portrait photo in Riverview Park in Renton, Washington of Ashley.' },
    { src: ashley202306_10, href: ashley202306_10, width: 667, height: 1000, originalAlt: 'Sports portrait photo in Riverview Park in Renton, Washington of Ashley.' }

  ];

  return (
    <>
      <ImageGallery
        title="Ashley 06/2023"
        name="Ashley"
        date="June, 2023"
        location="Riverview Park in Renton, Washington"
        photoArray={photos}
        returnLink="/portfolio/portraits"
      />
    </>
  );
};

/*-----------------------------------------------------------------------

/* Zoey 07/2023 */
const Zoey202307 = () => {
  const photos = [
    { src: zoey202307_01, href: zoey202307_01, width: 667, height: 1000, originalAlt: 'Nature portrait photo in Wildwood Park in Puyallup, Washington of Zoey.' },
    { src: zoey202307_02, href: zoey202307_02, width: 667, height: 1000, originalAlt: 'Nature portrait photo in Wildwood Park in Puyallup, Washington of Zoey.' },
    { src: zoey202307_03, href: zoey202307_03, width: 667, height: 1000, originalAlt: 'Nature portrait photo in Wildwood Park in Puyallup, Washington of Zoey.' },
    { src: zoey202307_04, href: zoey202307_04, width: 667, height: 1000, originalAlt: 'Nature portrait photo in Wildwood Park in Puyallup, Washington of Zoey.' },
    { src: zoey202307_05, href: zoey202307_05, width: 667, height: 1000, originalAlt: 'PNature portrait photo in Wildwood Park in Puyallup, Washington of Zoey.' },
    { src: zoey202307_06, href: zoey202307_06, width: 667, height: 1000, originalAlt: 'Nature portrait photo in Wildwood Park in Puyallup, Washington of Zoey.' },
    { src: zoey202307_07, href: zoey202307_07, width: 667, height: 1000, originalAlt: 'Nature portrait photo in Wildwood Park in Puyallup, Washington of Zoey.' }
  ];

  return (
    <ImageGallery
      title="Zoey & Maddy 07/2023"
      name="Zoey & Maddy"
      date="July, 2023"
      location="Wildwood Park in Puyallup, WA"
      photoArray={photos}
      returnLink="/portfolio/portraits"
    />
  );
};

/*-----------------------------------------------------------------------

/* Jake & Gabby 12/2023 */
const JakeGabby202312 = () => {
  const photos = [
    { src: jakegabby202312_01, href: jakegabby202312_01, width: 667, height: 1000, originalAlt: 'Maternity portrait photo in Bonney Lake, Washington of Jake and Gabby.' },
    { src: jakegabby202312_02, href: jakegabby202312_02, width: 667, height: 1000, originalAlt: 'Maternity portrait photo in Bonney Lake, Washington of Jake and Gabby.' },
    { src: jakegabby202312_03, href: jakegabby202312_03, width: 667, height: 1000, originalAlt: 'Maternity portrait photo in Bonney Lake, Washington of Jake and Gabby.' },
    { src: jakegabby202312_04, href: jakegabby202312_04, width: 667, height: 1000, originalAlt: 'Maternity portrait photo in Bonney Lake, Washington of Jake and Gabby.' },
    { src: jakegabby202312_05, href: jakegabby202312_05, width: 667, height: 1000, originalAlt: 'Maternity portrait photo in Bonney Lake, Washington of Jake and Gabby.' }
  ];

  return (
    <>
      <ImageGallery
        title="Jake & Gabby 12/2023"
        name="Jake & Gabby"
        date="December, 2023"
        location="Bonney Lake, Washington"
        photoArray={photos}
        returnLink="/portfolio/portraits"
      />
    </>
  );
};

/*-----------------------------------------------------------------------

/* Enzio 02/2024 */
const Enzio202402 = () => {
  const photos = [
    { src: enzio202402_01, href: enzio202402_01, width: 667, height: 1000, originalAlt: 'Newborn portrait photo in Bonney Lake, Washington of Enzio.' },
    { src: enzio202402_02, href: enzio202402_02, width: 667, height: 1000, originalAlt: 'Newborn portrait photo in Bonney Lake, Washington of Enzio.' },
    { src: enzio202402_03, href: enzio202402_03, width: 667, height: 1000, originalAlt: 'Newborn portrait photo in Bonney Lake, Washington of Enzio.' },
    { src: enzio202402_04, href: enzio202402_04, width: 1000, height: 667, originalAlt: 'Newborn portrait photo in Bonney Lake, Washington of Enzio.' },
  ];

  return (
    <>
      <ImageGallery
        title="Enzio 02/2024"
        name="Enzio"
        date="February, 2024"
        location="Bonney Lake, Washington"
        photoArray={photos}
        returnLink="/portfolio/portraits"
      />
    </>
  );
};

/*-----------------------------------------------------------------------

/* Lily 03/2024 */
const Lily202403 = () => {
  const photos = [
    {src: lily202403_01, href: lily202403_01, width: 668, height: 1000, originalAlt: 'portrait'},
    {src: lily202403_02, href: lily202403_02, width: 666, height: 1000, originalAlt: 'portrait'},
    {src: lily202403_03, href: lily202403_03, width: 666, height: 1000, originalAlt: 'portrait'},
    {src: lily202403_04, href: lily202403_04, width: 666, height: 1000, originalAlt: 'portrait'},
    {src: lily202403_05, href: lily202403_05, width: 666, height: 1000, originalAlt: 'portrait'},
    {src: lily202403_06, href: lily202403_06, width: 666, height: 1000, originalAlt: 'portrait'},
    {src: lily202403_07, href: lily202403_07, width: 666, height: 1000, originalAlt: 'portrait'},
    {src: lily202403_08, href: lily202403_08, width: 666, height: 1000, originalAlt: 'portrait'},
    {src: lily202403_09, href: lily202403_09, width: 668, height: 1000, originalAlt: 'portrait'},
    {src: lily202403_10, href: lily202403_10, width: 666, height: 1000, originalAlt: 'portrait'},
    {src: lily202403_11, href: lily202403_10, width: 666, height: 1000, originalAlt: 'portrait'},
    {src: lily202403_12, href: lily202403_12, width: 666, height: 1000, originalAlt: 'portrait'},
    {src: lily202403_13, href: lily202403_13, width: 666, height: 1000, originalAlt: 'portrait'}
  ];

  return (
    <>
      <ImageGallery
        title="Lily 03/2024"
        name="Lily"
        date="March, 2024"
        location="University of Washington in Seattle, Washington"
        photoArray={photos}
        returnLink="/portfolio/portraits"
      />
    </>
  );
};


/*-----------------------------------------------------------------------

/* Jeny 03/2024 */
const Jeny202403 = () => {
  const photos = [
    {src: jeny202403_01, href: jeny202403_01, width: 668, height: 1000, originalAlt: 'portrait'},
    {src: jeny202403_02, href: jeny202403_02, width: 666, height: 1000, originalAlt: 'portrait'},
    {src: jeny202403_03, href: jeny202403_03, width: 666, height: 1000, originalAlt: 'portrait'},
    {src: jeny202403_04, href: jeny202403_04, width: 666, height: 1000, originalAlt: 'portrait'},
    {src: jeny202403_05, href: jeny202403_05, width: 666, height: 1000, originalAlt: 'portrait'},
    {src: jeny202403_06, href: jeny202403_06, width: 666, height: 1000, originalAlt: 'portrait'},
    {src: jeny202403_07, href: jeny202403_07, width: 666, height: 1000, originalAlt: 'portrait'},
    {src: jeny202403_08, href: jeny202403_08, width: 666, height: 1000, originalAlt: 'portrait'},
    {src: jeny202403_09, href: jeny202403_09, width: 668, height: 1000, originalAlt: 'portrait'},
    {src: jeny202403_10, href: jeny202403_10, width: 666, height: 1000, originalAlt: 'portrait'},
    {src: jeny202403_11, href: jeny202403_11, width: 666, height: 1000, originalAlt: 'portrait'}
  ];

  return (
    <>
      <ImageGallery
        title="Jeny 03/2024"
        name="Jeny"
        date="March, 2024"
        location="University of Washington in Seattle, Washington"
        photoArray={photos}
        returnLink="/portfolio/portraits"
      />
    </>
  );
};

/*-----------------------------------------------------------------------

/* Anzhela 04/2024 */
const Anzhela202404 = () => {
  const photos = [
    {src: anzhela202404_01, href: anzhela202404_01, width: 668, height: 1000, originalAlt: 'portrait'},
    {src: anzhela202404_02, href: anzhela202404_02, width: 666, height: 1000, originalAlt: 'portrait'},
    {src: anzhela202404_03, href: anzhela202404_03, width: 666, height: 1000, originalAlt: 'portrait'},
    {src: anzhela202404_04, href: anzhela202404_04, width: 666, height: 1000, originalAlt: 'portrait'},
    {src: anzhela202404_05, href: anzhela202404_05, width: 666, height: 1000, originalAlt: 'portrait'},
    {src: anzhela202404_06, href: anzhela202404_06, width: 666, height: 1000, originalAlt: 'portrait'},
    {src: anzhela202404_07, href: anzhela202404_07, width: 666, height: 1000, originalAlt: 'portrait'},
    {src: anzhela202404_08, href: anzhela202404_08, width: 666, height: 1000, originalAlt: 'portrait'},
    {src: anzhela202404_09, href: anzhela202404_09, width: 668, height: 1000, originalAlt: 'portrait'},
    {src: anzhela202404_10, href: anzhela202404_10, width: 666, height: 1000, originalAlt: 'portrait'},
    {src: anzhela202404_11, href: anzhela202404_11, width: 666, height: 1000, originalAlt: 'portrait'}
  ];

  return (
    <>
      <ImageGallery
        title="Anzhela 04/2024"
        name="Anzhela"
        date="April, 2024"
        location="Gas Works Park in Seattle, Washington"
        photoArray={photos}
        returnLink="/portfolio/portraits"
      />
    </>
  );
};

/*-----------------------------------------------------------------------

/* Miscellaneous */
const Misc = () => {
  const photos = [
    { src: misc_01, href: misc_01, width: 666, height: 1000, originalAlt: 'Misc portrait photo.' },
    { src: misc_02, href: misc_02, width: 1000, height: 666, originalAlt: 'Misc portrait photo.' },
    { src: misc_03, href: misc_03, width: 1000, height: 666, originalAlt: 'Misc portrait photo.' },
    { src: misc_04, href: misc_04, width: 1000, height: 1000, originalAlt: 'Misc portrait photo.' },
    { src: misc_05, href: misc_05, width: 1000, height: 666, originalAlt: 'Misc portrait photo.' },
    { src: misc_06, href: misc_06, width: 1000, height: 1000, originalAlt: 'Misc portrait photo.' },
    { src: misc_07, href: misc_07, width: 1000, height: 666, originalAlt: 'Misc portrait photo.' },
    { src: misc_08, href: misc_08, width: 1000, height: 666, originalAlt: 'Misc portrait photo.' },
    { src: misc_09, href: misc_09, width: 800, height: 1000, originalAlt: 'Misc portrait photo.' },
    { src: misc_10, href: misc_10, width: 800, height: 1000, originalAlt: 'Misc portrait photo.' },
    { src: misc_11, href: misc_11, width: 750, height: 1000, originalAlt: 'Misc portrait photo.' },
    { src: misc_12, href: misc_12, width: 750, height: 1000, originalAlt: 'Misc portrait photo.' }
  ];

  return (
    <>
      <ImageGallery
        title="Miscellaneous"
        name="Miscellaneous"
        date=""
        location=""
        photoArray={photos}
        returnLink="/portfolio/portraits"
      />
    </>
  );
};

export {
  Zoey202307,
  Ashley202306,
  Lauren202210,
  JakeGabby202312,
  Jenny202203,
  Damaris202202,
  Rhyann202211,
  Enzio202402,
  Lily202403,
  Jeny202403,
  Anzhela202404,
  Misc
}
