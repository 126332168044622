/**
 * Displays the navigation bar on the top of the page for mobile devices
 *
 * @file   MobileNav.jsx.
 * @author Jacob Summerville.
 * @since  02/21/2024
 */

import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu'

import "./../../styles/header.scss";
import "./../../styles/theme.scss";

const MobileNav = () => {
  const [isOpen, setOpen] = useState(false)

  const handleIsOpen = () => {
    setOpen(!isOpen)
  }

  const closeSideBar = () => {
    setOpen(false)
  }

  return (
    <section className="navigation">
      <Menu className="nav-menu"
        isOpen={isOpen}
        onOpen={handleIsOpen}
        onClose={handleIsOpen}
      >
        <li><Link onClick={closeSideBar} to={"/"}>Home</Link></li>
        <li><Link onClick={closeSideBar} to={"/portfolio"}>Portfolio</Link></li>
        <li><Link onClick={closeSideBar} to={"/about"}>About</Link></li>
        <li><Link onClick={closeSideBar} to={"/contact"}>Contact</Link></li>
      </Menu>

      <div className="mobile-nav-logo">
        <Link to={"/"}>JSPhotos</Link>
      </div>
    </section>
  );
};

export default MobileNav;