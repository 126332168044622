/**
 * Displays the real estate events
 *
 * @file   RealEstate.jsx.
 * @author Jacob Summerville.
 * @since  11/23/2024
 */

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';
import List from '@mui/joy/List';
import ListDivider from '@mui/joy/ListDivider';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';

import OHDental from "./../../assets/realestate/OHDental/ohdental1.webp";

import "./../../styles/theme.scss";
import "./../../styles/portfolio.scss";

const RealEstate = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>Real Estate Portfolio</title>
      </Helmet>

      <div className="portfolio-image">
        <h1>Portfolio</h1>
      </div>

      <div className="portfolio-div">
        <div className="portfolio-nav-div">
          <div className="portfolio-nav">
            <List role="menubar" orientation="horizontal">
              <ListItem role="none">
                <ListItemButton role="menuitem">
                  <Link to={"/portfolio/portraits"}>Portraits</Link>
                </ListItemButton>
              </ListItem>
              <ListDivider />
              <ListItem role="none">
                <ListItemButton role="menuitem" selected>
                  <Link to={"/portfolio/realestate"}>Real Estate</Link>
                </ListItemButton>
              </ListItem>
              <ListDivider />
              <ListItem role="none">
                <ListItemButton role="menuitem">
                  <Link to={"/portfolio/nature"}>Nature</Link>
                </ListItemButton>
              </ListItem>
            </List>
          </div>
        </div>

        <div id="nature-events">
          <div>
            <Link to={"/portfolio/ohdental"}>
              <img src={OHDental} alt="Real estate photography for Ossman Harding Dental" />
              <p className="portrait-model">Ossman Harding Dental</p>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default RealEstate;