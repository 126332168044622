/**
 * Contains all realestate photos
 *
 * @file   RealEstateImages.jsx.
 * @author Jacob Summerville.
 * @since  11/23/2024
 */

import React from "react";

import "./../../styles/theme.scss";
import "./../../styles/portfolio.scss";

import ImageGallery from "./ImageGallery";

import ohdental01 from "./../../assets/realestate/OHDental/ohdental1.webp";
import ohdental02 from "./../../assets/realestate/OHDental/ohdental2.webp";
import ohdental03 from "./../../assets/realestate/OHDental/ohdental3.webp";
import ohdental04 from "./../../assets/realestate/OHDental/ohdental4.webp";
import ohdental05 from "./../../assets/realestate/OHDental/ohdental5.webp";
import ohdental06 from "./../../assets/realestate/OHDental/ohdental6.webp";
import ohdental07 from "./../../assets/realestate/OHDental/ohdental7.webp";
import ohdental08 from "./../../assets/realestate/OHDental/ohdental8.webp";

/*-----------------------------------------------------------------------

/* Washington, USA */
const OHDental = () => {
  const photos = [
    { src: ohdental01, href: ohdental01, width: 1000, height: 666, originalAlt: "Dentist office" },
    { src: ohdental02, href: ohdental02, width: 1000, height: 666, originalAlt: "Dentist office" },
    { src: ohdental03, href: ohdental03, width: 1000, height: 666, originalAlt: "Dentist office" },
    { src: ohdental04, href: ohdental04, width: 1000, height: 666, originalAlt: "Dentist office" },
    { src: ohdental05, href: ohdental05, width: 1000, height: 666, originalAlt: "Dentist office" },
    { src: ohdental06, href: ohdental06, width: 1000, height: 666, originalAlt: "Dentist office" },
    { src: ohdental07, href: ohdental07, width: 1000, height: 666, originalAlt: "Dentist office" },
    { src: ohdental08, href: ohdental08, width: 1000, height: 666, originalAlt: "Dentist office" }
  ];

  return (
    <>
      <ImageGallery
        title="Ossman Harding Dental"
        name="Ossman Harding Dental"
        date="April 2024"
        location="Bonney Lake, WA"
        photoArray={photos}
        returnLink="/portfolio/realestate"
      />
    </>
  );
};

export {
  OHDental
}