/**
 * Contains photos from a portrait photoshoot
 *
 * @file   Zoey-2023-07.jsx.
 * @author Jacob Summerville.
 * @since  02/18/2024
 */

import React from "react";
import PhotoAlbum from "react-photo-album";
import Stack from '@mui/material/Stack';
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import "./../../styles/theme.scss";
import "./../../styles/portfolio.scss";

const SubHeading = (props) => {
  if (props.date !== '' && props.location !== '') {
    if (props.location.length < 35) {
      return (<h2>{props.date} | {props.location}</h2>)
    } else {
      return (<h2>{props.date}<br/>{props.location}</h2>)
    }
  } else if (props.date !== '') {
    return (<h2>{props.date}</h2>)
  } else if (props.location !== '') {
    return (<h2>{props.location}</h2>)
  }
  else {
    return (<></>)
  }
}

const ImageGallery = (props) => {
  window.scrollTo(0, 0)

  return (
    <>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>

      <hr />
      <div className="image-gallery">

        <div className="portfolio-return">
          <Link to={props.returnLink}>
            <Stack direction="row" alignItems="center" gap={1}>
              <ArrowBackIosIcon />
              <p>Return to Portfolio</p>
            </Stack>
          </Link>
        </div>

        <h1>{props.name}</h1>
        <SubHeading date={props.date} location={props.location} />

        <div className="image-gallery-photos">
          <PhotoAlbum layout="columns" photos={props.photoArray}
            renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
              <a
                href={photo.href}
                style={wrapperStyle}
                target="_blank"
                rel="noreferrer noopener"
              >
                {renderDefaultPhoto({ wrapped: true })}
              </a>
            )} />
        </div>
      </div>
    </>
  );
};

export default ImageGallery;
