/**
 * Displays the home page description
 *
 * @file   Description.jsx.
 * @author Jacob Summerville.
 * @since  02/16/2024
 */

import React    from "react";
import { Link } from 'react-router-dom';

import SelfPortrait from "./../../assets/img/home-self.webp"

import "./../../styles/theme.scss";
import "./../../styles/home.scss";

const Description = () => {
  return (
    <div id="home-description">
      <div className="desc-photo-div">
        <img className="desc-photo" src={SelfPortrait} alt="Jacob Summerville" />
      </div>

      <div className="desc-text">
        <h2>Jacob Summerville</h2>

        <p>
          I am a photographer who loves capturing the beauty and diversity of people and nature. I enjoy exploring different places and cultures, and finding the stories behind every face and scenery. My passion for photography started when I was a child, and I have been honing my skills ever since.
          <br/><br/>
          My portfolio showcases a range of portrait and nature photography, from candid moments to stunning vistas. I use various techniques and tools to create images that reflect my vision and style. I believe that photography is not only an art, but also a way of communication and expression. Through my photos, I hope to inspire and connect with others who share my love for photography.
        </p>

        <div className="desc-link">
          <Link to={"/about"}>Learn More</Link>
        </div>
      </div>
    </div>
  );
};

export default Description;