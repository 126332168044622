/**
 * Displays the focuses page info
 *
 * @file   Focus.jsx.
 * @author Jacob Summerville.
 * @since  02/17/2024
 */

import React from "react";
import { Link } from 'react-router-dom';

import NatureImg from "./../../assets/img/home-nature.webp"
import PortraitImg from "./../../assets/portrait/2023-07-zoey/zoey1.webp"
import RealEstateImg from "./../../assets/realestate/OHDental/ohdental9.webp"

import "./../../styles/theme.scss";
import "./../../styles/home.scss";

const Focus = () => {
  return (
    <div id="focus-div">
      <div>
        <Link to={"/portfolio/portraits"}>
          <img src={PortraitImg} alt="Portrait photography" />
          <p>Portraits</p>
        </Link>
      </div>
      <div>
        <Link to={"/portfolio/realestate"}>
          <img src={RealEstateImg} alt="Real Estate photography" />
          <p>Real Estate</p>
        </Link>
      </div>
      <div  id="focus-nature">
        <Link to={"/portfolio/nature"}>
          <img src={NatureImg} alt="Nature photography" />
          <p>Nature</p>
        </Link>
      </div>
    </div>
  );
};

export default Focus;