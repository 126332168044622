/**
 * Displays the navigation bar on the top of the page for desktops
 *
 * @file   DesktopNav.jsx.
 * @author Jacob Summerville.
 * @since  02/21/2024
 */

import React from "react";
import { Link } from 'react-router-dom';

import "./../../styles/header.scss";
import "./../../styles/theme.scss";

const DesktopNav = () => {
  return (
    <section className="navigation">
      <div className="nav-container">
        <nav className="header-nav nav-left">
          <ul className="nav-list-left">
            <li><Link to={"/"}>Home</Link></li>
            <li><Link to={"/portfolio"}>Portfolio</Link></li>
          </ul>
        </nav>

        <div className="nav-logo">
          <Link to={"/"}>JSPhotos</Link>
        </div>

        <nav className="header-nav nav-right">
          <ul className="nav-list-right">
            <li><Link to={"/about"}>About</Link></li>
            <li><Link to={"/contact"}>Contact</Link></li>
          </ul>
        </nav>
      </div>
    </section>
  );
};

export default DesktopNav;